
    .theme-line-0 {
      --layer1: #202020;}
    
    .theme-line-2 {
      --layer2: #202020;
      --layer1: #202020;}
    
    .theme-line-4 {
      --layer3: #202020;
      --layer2: #202020;
      --layer1: #202020;}
    
    .theme-line-15 {
      --layer1: #202020;
      --layer2: #202020;}
    
    .theme-line-35 {
      --layer2: #202020;}
    
    .theme-line-147 {
      --layer2: #000;
      --layer1: #000;}
    
      /* Styles for the ticketing system message interface */

/* Container for each message */
.message-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1em;
  max-width: 80%;
}

/* Client message styles (align to the right) */
.client-message {
  background-color: #e0f7fa;
  color: #00796b;
  padding: 10px;
  border-radius: 10px 0 10px 10px;
  align-self: flex-end;
  max-width: 70%;
  margin-left: auto;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Suaviza o scroll em dispositivos móveis */
}

/* Analyst message styles (align to the left) */
.analyst-message {
  background-color: #f1f8e9;
  color: #33691e;
  padding: 10px;
  border-radius: 0 10px 10px 10px;
  max-width: 70%;
  margin-right: auto;
}

/* Message text */
.message-text {
  font-size: 0.95em;
  line-height: 1.4;
}

/* Timestamp for each message */
.message-timestamp {
  font-size: 0.8em;
  color: #666;
  margin-top: 5px;
}

/* Optional: Styling for profile icons */
.message-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Aligns icons to left or right based on the message type */
.client-message .message-icon {
  margin-left: auto;
  margin-right: 10px;
}

.analyst-message .message-icon {
  margin-right: 10px;
}
